const dateExtraFooter_CN = {
  dateExtraFooter_today: '今天',
  dateExtraFooter_one: '前一天',
  dateExtraFooter_three: '前三天',
  dateExtraFooter_seven: '前七天',
  dateExtraFooter_week: '本周',
  dateExtraFooter_month: '本月',
  dateExtraFooter_lastMonth: '上月',
};

export default dateExtraFooter_CN;