const ephemerisManagement_CN = {
  ephemerisManagement_dashboard: '工作台',
  ephemerisManagement_equipment_management: '设备管理',
  ephemerisManagement_title: '星历管理',
  ephemerisManagement_address_tab: '地址管理',
  ephemerisManagement_table_tab: '更新记录',
  ephemerisManagement_address_title: '星历下载地址',
  ephemerisManagementr_input_address: '请输入星历下载地址',
  ephemerisManagementr_manually_download: '手动下载',
  ephemerisManagement_table_date: '更新日期',
  ephemerisManagement_table_time: '更新时间',
  ephemerisManagement_table_status: '状态',
  ephemerisManagement_table_site: '下载网址',
  ephemerisManagement_status_success: '成功',
  ephemerisManagement_status_fail: '失败',
  ephemerisManagement_status_loading: '加载中',
  ephemerisManagement_list_empty: '暂无数据',
};
export default ephemerisManagement_CN;