const rechargeSearch_CN = {
  rechargeSearch_crumb1: '报表管理',
  rechargeSearch_crumb2: '充值查询',
  rechargeSearch_title: '充值列表',
  rechargeSearch_search: '查询',
  rechargeSearch_export: '导出',
  rechargeSearch_reset: '重置',
  rechargeSearch_th1: '序号',
  rechargeSearch_th2: 'IMEI',
  rechargeSearch_th3: '所属组织',
  rechargeSearch_th4: 'ICCID',
  rechargeSearch_th5: 'SIM卡号',
  rechargeSearch_th6: '充值金额(元)',
  rechargeSearch_th7: '充值状态',
  rechargeSearch_th8: '订单号',
  rechargeSearch_th9: '订单生成时间',
  rechargeSearch_th10: '描述',
  rechargeSearch_options1: '订单号',
  rechargeSearch_options2: '订单时间范围',
  rechargeSearch_placehodler1: '请选择查询方式',
  rechargeSearch_placehodler2: '请输入订单号',
  rechargeSearch_typeEmpty: '请选择查询方式',
  rechargeSearch_numberEmpty: '请输入订单号',
  rechargeSearch_noData: '暂无数据',
  rechargeSearch_total: '共',
  rechargeSearch_unit: '条',
  rechargeSearch_opera: '操作',
  rechargeSearch_updateSuc: '更新成功',
  rechargeSearch_updateFail: '更新失败',
  rechargeSearch_update: '更新',
};

export default rechargeSearch_CN;