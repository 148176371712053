import React from 'react';
import { Drawer, Button, Form, Input, message } from 'antd';
import styles from './index.module.less';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getStore } from '../../utils/localStorage';
import { replace } from '../../utils/router/routeMethods';
import { removeStore } from '../../utils/localStorage';

class EditPassword extends React.Component {
  constructor(props){
    super(props)
  }
  
  componentDidMount() {
    const {
      form: { setFieldsValue },
    } = this.props;
    setFieldsValue({
      username: getStore('username')
    })
  }

  onClose = () => {
    this.props.changePassword(false)
  }

  onSubmit = () => {
    const {
      form: { validateFields, resetFields }, EditUserPassword
    } = this.props;
    let _this = this;
    validateFields((errors, values) => {
      if(!errors){
        console.log(values)
        const {newPassword, confirmNewPassword, oldPassword} = values;
        const param = {
          oldPassword: oldPassword,
          newPassword: newPassword
        }

        if(newPassword !== confirmNewPassword) {
          message.error('两次输入的新密码不一致！')
          return;
        }

        EditUserPassword({
          param,
          callBack: (res) => {
            if(res.status === 200) {
              if(res.data.data) {
                resetFields()
                message.success('修改成功', 5, function () {
                  _this.logOut();
                })
              }else {
                message.error(res.data.msg)
              }
            }
          } 
        })
      }
    });
  }

 /**
  * 退出登录
  * 清空所有缓存对象
  */
  logOut = (e) => {
    removeStore('token');
    removeStore('historyRouters');
    removeStore('menuKeyPath');
    removeStore('expiration');
    removeStore('username');
    removeStore('authorMenuList');
    removeStore('routerList');
    removeStore('routerId');
    removeStore('childRouterList');
    removeStore('childRouterId');
    if (global.socket) {
      global.socket.close();
      global.socket = null;
    }
    const { loginExit } = this.props;
    loginExit();
    replace('login');
  }

  handleConfirmPassword = (rule, value, callback) => {
    const { getFieldValue  } = this.props.form
    if (value && value !== getFieldValue('newPassword')) {
      callback('两次输入的新密码不一致！')
    }
    callback();
  }

  render() {
    const  { visible, form: { getFieldDecorator } } = this.props;
    return (
      <Drawer
        title='修改密码'
        width={400}
        placement='right'
        closable={false}
        onClose={this.onClose}
        visible={visible}
        zIndex='99999'
      >
        <Form>
          <Form.Item label='用户名'>
            {
              getFieldDecorator('username')(<Input disabled={true} allowClear autoComplete='off'/>)
            }
        </Form.Item>
        <Form.Item label='旧密码'>
            {
              getFieldDecorator('oldPassword', {
                rules: [{ required: true, message: '请输入旧密码' }],
              })(<Input placeholder='请输入旧密码' maxLength={25} allowClear autoComplete='off'/>)
            }
        </Form.Item>
        <Form.Item label='新密码'>
            {
              getFieldDecorator('newPassword', {
                rules: [
                  { required: true, message: '请输入新密码' },
                  {
                    min: 8,
                    max: 25,
                    message: '格式不正确，应该8-25字符！'
                  },
                  {
                    pattern: new RegExp(/(?=.*[0-9])(?=.*[a-zA-Z])^[^\s]+$|(?=.*[0-9])(?=.*[^a-zA-Z0-9])^[^\s]+$|(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])^[^\s]+$|(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])^[^\s]+$/),
                    message: '新密码必须包含大小写字母、数字和特殊字符(不含空格)中的两者!',
                  },
                ],
              })(<Input placeholder='请输入新密码' allowClear autoComplete='off'/>)
            }
        </Form.Item>
        <Form.Item label='确认新密码'>
            {
              getFieldDecorator('confirmNewPassword', {
                rules: [
                  { required: true, message: '请确认新密码' },
                  {
                    min: 8,
                    max: 25,
                    message: '格式不正确，应该8-25字符！'
                  },
                  {
                    pattern: new RegExp(/(?=.*[0-9])(?=.*[a-zA-Z])^[^\s]+$|(?=.*[0-9])(?=.*[^a-zA-Z0-9])^[^\s]+$|(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])^[^\s]+$|(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])^[^\s]+$/),
                    message: '新密码必须包含大小写字母、数字和特殊字符(不含空格)中的两者!',
                  },
                  {
                    validator: this.handleConfirmPassword
                  }
                ],
              })(<Input placeholder='请确认新密码' allowClear autoComplete='off'/>)
            }
        </Form.Item>
      </Form>
        <div className={styles['drawer-footerBtns']}>
          <Button onClick={this.onClose}>
            <FormattedMessage id="organizationAndUser_cancel" />
          </Button>
          <Button onClick={this.onSubmit} type="primary">
            <FormattedMessage id="organizationAndUser_confirm" />
          </Button>
        </div>
      </Drawer>
    )
  }
}
const EditPasswordForm = Form.create({ name: 'EditPassword' })(EditPassword);
export default injectIntl(EditPasswordForm);