const terminalManagement_CN = {
    // hello: '你好，世界！',
    // name: '我的名字是 {name}',
    // home_workbench: '工作台',
    // home_wish: '祝你开心每一天！',
    // home_terminal_management: '终端管理',
    // home_top_up_query: '充值查询',
    // home_quick_navigation: '快捷导航',
    // home_dynamic: '动态',
    // home_construction: '施工中...',
    terminalManagement_title: '终端管理',

    /**
     * 下发短信部分
     */
    terminalManagement_send_sms: '下发短信',
    terminalManagement_confirm_title: '操作确认',
    terminalManagement_confirm_content: '确认要执行此操作吗?',
    terminalManagement_confirm: '确认',
    terminalManagement_cancel: '取消',
    terminalManagement_terminal_password: '终端密码',
    terminalManagement_input_terminalpwd: '请输入终端密码',
    terminalManagement_instruction_title: '指令类型',
    terminalManagement_set_password: '设置密码',
    terminalManagement_set_upplatformcconnect: '设置连接上级平台',
    terminalManagement_set_apn: '设置APN',
    terminalManagement_set_terminalid: '设置终端ID',
    terminalManagement_terminal_reset: '终端复位',
    terminalManagement_factory_reset: '恢复出厂设置',
    terminalManagement_remote_upgrade: '远程升级',
    terminalManagement_set_ponitangle: '设置拐点补偿角度',
    terminalManagement_set_awakeningdormant: '设置苏醒(休眠)模式',
    terminalManagement_close_smschannel: '关闭短信通道',
    terminalManagement_set_freightplatformconnect: '设置连接货运平台',
    terminalManagement_set_terminal_register_msg: '设置终端注册信息',
    terminalManagement_set_platenumber: '设置车牌号',
    terminalManagement_set_mileagebaseValue: '设置里程基值',
    terminalManagement_set_undervoltagealarmthreshold: '设置欠压报警阈值',
    terminalManagement_set_positionmode: '设置定位模式',
    terminalManagement_set_vehiclestatejudgmentmode: '设置车辆状态判断模式',
    terminalManagement_set_driftthreshold: '设置漂移阈值',
    terminalManagement_set_return_interval: '设置回传间隔',
    terminalManagement_default_value: '默认值',
    terminalManagement_read_value: '读取',
    terminalManagement_send_title: '下发',
    terminalManagement_send_empty_ip_title: '下发空IP',
    terminalManagement_send_customText: '下发自定义文本',
    terminalManagement_query_device_params: '查询终端参数',
    terminalManagement_handle_type: '操作类型',
    terminalManagement_select_handletype: '请选择操作类型',
    terminalManagement_sms_normal: '短信通道正常',
    terminalManagement_format_type: '格式分类',
    terminalManagement_select_format: '请选择格式',
    terminalManagement_format_one: '格式1',
    terminalManagement_format_two: '格式2',
    terminalManagement_file_name: '文件名',
    terminalManagement_input_filename: '请输入文件名',
    terminalManagement_user_name: '用户名',
    terminalManagement_input_username: '请输入用户名',
    terminalManagement_password: '密码',
    terminalManagement_input_password: '请输入密码',
    terminalManagement_ip_address: 'IP地址',
    terminalManagement_input_ipaddress: '请输入IP地址',
    terminalManagement_ipdomain_validate: '请输入正确的域名或IP',
    terminalManagement_port: '端口号',
    terminalManagement_input_port: '请输入端口号',
    terminalManagement_port_validate: '请输入正确的端口号,输入范围0-65535',
    terminalManagement_path: '路径',
    terminalManagement_input_path: '请输入路径',
    terminalManagement_input_apn: '请输入APN',
    terminalManagement_apn_username: 'APN用户名',
    terminalManagement_input_apnusername: '请输入APN用户名',
    terminalManagement_apn_password: 'APN密码',
    terminalManagement_input_apnpassword: '请输入APN密码',
    terminalManagement_sleep_mode: '休眠模式',
    terminalManagement_select_sleepMode: '请选择休眠模式',
    terminalManagement_no_sleep: '不休眠',
    terminalManagement_engine_status: '按照发动机状态',
    terminalManagement_is_reportlocation: '是否上报位置信息',
    terminalManagement_select_isreportlocation: '请选择是否上报位置信息',
    terminalManagement_sleep_noreport: '休眠时不上报',
    terminalManagement_sleep_report: '休眠时上报',
    terminalManagement_location_reportinterval: '位置上报间隔(秒)',
    terminalManagement_input_locationreportinterval: '请输入位置上报间隔',
    terminalManagement_number_validate: '输入值范围为{digits}',
    terminalManagement_heart_reportinterval: '心跳上报间隔(秒)',
    terminalManagement_input_heartreportinterval: '请输入心跳上报间隔',
    terminalManagement_terminal_phone: '终端手机号',
    terminalManagement_input_terminalphone: '请输入终端手机号',
    terminalManagement_phone_validate: '请输入正确的终端手机号',
    terminalManagement_terminal_id: '终端ID',
    terminalManagement_input_terminalid: '请输入终端ID',
    terminalManagement_distance_value: '距离阈值',
    terminalManagement_input_distancevalue: '请输入距离阈值',
    terminalManagement_integer_validate: '输入类型为非负整数',
    terminalManagement_ip_type: 'IP类别',
    terminalManagement_signal_line_switching: '信号线切换',
    terminalManagement_select_iptype: '请选择IP类别',
    terminalManagement_server_address: '服务器地址',
    terminalManagement_input_serveraddress: '请输入服务器地址',
    terminalManagement_sim_number: 'SIM卡号',
    terminalManagement_input_simnumber: '请输入SIM卡号',
    terminalManagement_integerrange_validate: '输入范围为{range}位整数',
    terminalManagement_numberAZ_validate: '输入类型为数字和大写字母',
    terminalManagement_plate_color: '车牌颜色',
    terminalManagement_select_platecolor: '请选择车牌颜色',
    terminalManagement_no_registration: '未上牌',
    terminalManagement_blue_color: '蓝色',
    terminalManagement_yellow_color: '黄色',
    terminalManagement_black_color: '黑色',
    terminalManagement_white_color: '白色',
    terminalManagement_green_color: '绿色',
    terminalManagement_other_color: '其他',
    terminalManagement_mileage: '总里程(KM)',
    terminalManagement_input_mileage: '请输入总里程',
    terminalManagement_old_password: '原密码',
    terminalManagement_input_oldpassword: '请输入原密码',
    terminalManagement_new_password: '新密码',
    terminalManagement_input_newpassword: '请输入新密码',
    terminalManagement_plate_number: '车牌号',
    terminalManagement_input_platenumber: '请输入车牌号',
    terminalManagement_platnumber_validate: '输入类型为中文、数字和大写字母和',
    terminalManagement_vin_code: 'VIN码',
    terminalManagement_input_vincode: '请输入VIN码',
    terminalManagement_numberAZrange_validate: '请输入{num}位数字或大写字母',
    terminalManagement_compensation_mode: '拐点补偿模式',
    terminalManagement_select_compensationmode: '请选择拐点补偿模式',
    terminalManagement_azimuth: '方位角',
    terminalManagement_gyroscope: '陀螺仪',
    terminalManagement_compensation_angle: '拐点补偿角度',
    terminalManagement_input_compensationangle: '请输入拐点补偿角度',
    terminalManagement_zero_compensationangle: '0代表不需要拐点补偿',
    terminalManagement_location_type: '定位模式',
    terminalManagement_select_locationtype: '请选择定位模式',
    terminalManagement_gps_bigdipper: 'GPS+北斗',
    terminalManagement_bigdipper: '北斗',
    terminalManagement_base_station: '基站',
    terminalManagement_satellite_basestation: '卫星+基站',
    terminalManagement_all_satellitebasestation: '卫星+基站(全部上报)',
    terminalManagement_volatage: '电压值(V)',
    terminalManagement_input_volatage: '请输入电压值',
    terminalManagement_volatage_validate: '输入类型为数字(最多一位小数)',
    terminalManagement_server_name: '服务器名称',
    terminalManagement_select_servername: '请选择服务器名称',
    terminalManagement_no_server: '无可选服务器',
    terminalManagement_pattern: '车辆状态判断模式',
    terminalManagement_select_pattern: '请选择车辆状态判断模式',
    terminalManagement_accstatus_speed: '简单模式（ACC状态+速度）',
    terminalManagement_accstatus_speedvibration: '综合模式（ACC状态+速度+震动）',
    terminalManagement_self_learningmodel: '智能模式（自学习模式）',
    terminalManagement_speed_threshold: '速度阈值(km/h)',
    terminalManagement_input_speedthreshold: '请输入速度阈值',
    terminalManagement_vh_percentage: 'VH百分比(%)',
    terminalManagement_input_vhpercentage: '请输入VH百分比',
    terminalManagement_vibration_threshold: '振动阈值1(mg)',
    terminalManagement_input_vibration: '请输入振动阈值',
    terminalManagement_vibration_thresholtwo: '振动阈值2(mg)',
    terminalManagement_position_report_strategy: '位置汇报策略',
    terminalManagement_position_report_method: '位置汇报方案',
    terminalManagement_time_report: '定时汇报',
    terminalManagement_distance_report: '定距汇报',
    terminalManagement_time_distance_report: '定时和定距汇报',
    terminalManagement_acc_report: '根据ACC状态',
    terminalManagement_login_acc_report: '根据登录和ACC状态',
    terminalManagement_default_time_report_interval: '缺省时间汇报间隔(S)',
    terminalManagement_emergency_alarm_report_interval_s: '紧急报警时汇报间隔(S)',
    terminalManagement_sleep_report_interval_s: '休眠时汇报间隔(S)',
    terminalManagement_driver_not_login_report_interval_s: '驾驶员未登录时汇报间隔(S)',
    terminalManagement_default_distance_report_interval: '缺省距离汇报间隔(m)',
    terminalManagement_emergency_alarm_report_interval_m: '紧急报警时汇报间隔(m)',
    terminalManagement_sleep_report_interval_m: '休眠时汇报间隔(m)',
    terminalManagement_driver_not_login_report_interval_m: '驾驶员未登录时汇报间隔(m)',
    terminalManagement_input_default_time_report_interval: '请输入缺省时间汇报间隔(S)',
    terminalManagement_input_emergency_alarm_report_interval_s: '请输入紧急报警时汇报间隔(S)',
    terminalManagement_input_sleep_report_interval_s: '请输入休眠时汇报间隔(S)',
    terminalManagement_input_driver_not_login_report_interval_s: '请输入驾驶员未登录时汇报间隔(S)',
    terminalManagement_input_default_distance_report_interval: '请输入缺省距离汇报间隔(m)',
    terminalManagement_input_emergency_alarm_report_interval_m: '请输入紧急报警时汇报间隔(m)',
    terminalManagement_input_sleep_report_interval_m: '请输入休眠时汇报间隔(m)',
    terminalManagement_input_driver_not_login_report_interval_m: '请输入驾驶员未登录时汇报间隔(m)',
    terminalManagement_query_terminal_paramter: '查询终端参数',

};

export default terminalManagement_CN;
