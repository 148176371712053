const home_US = {
  hello: 'Hello, world!',
  name: 'my name is {name}',
  home_workbench: 'workbench',
  home_wish: 'Wish you happy every day！',
  home_terminal_management: 'Terminal Management',
  home_top_up_query: 'Top Up Query',
  home_quick_navigation: 'quick navigation',
  home_dynamic: 'dynamic',
  home_construction: 'construction...',
};

export default home_US;