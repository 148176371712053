import axios from '../utils/server/axios';

const ephemerisManagement = {
  /**
   * 获取星历下载地址列表
   */
  getSiteAddressList: (data) => axios('/api/ephemeris/management/list', 'POST', data),

  /**
   * 获取星历管理更新记录
   */
  getSiteDataList: () => axios('/api/ephemeris/management/all', 'POST'),

  /**
   * 手动下载
   */
  manuallyDownloadSite: (data) => axios('/api/ephemeris/management/downloadEphemeris', 'GET', data, 20000),

  /**
  * 新增星历下载地址
  */
  addSiteData: (data) => axios('/api/ephemeris/management/insert', 'POST', data),

  /**
  * 获取星历下载地址信息
  */
  getSiteInfo: (data) => axios('/api/ephemeris/management/ephemerisInfo', 'GET', data),

  /**
   * 修改星历下载地址
   */
  updateSiteData: (data) => axios('/api/ephemeris/management/update', 'POST', data),

  /**
   * 删除星历下载地址
   */
  deleteSite: (data) => axios('/api/ephemeris/management/delete', 'POST', data),
};

export default ephemerisManagement;