const logs_CN = {
  logs_crumb1: '首页',
  logs_crumb2: '系统管理',
  logs_title: '日志查询',
  logs_panelTit1: '查询',
  logs_param1: '时间',
  logs_param2: '操作用户',
  logs_param3: '操作内容',
  logs_export: '导出',
  logs_new: '新建',
  logs_panelTit2: '日志列表',
  logs_th1: '序号',
  logs_th2: '操作时间',
  logs_th3: 'IP地址',
  logs_th4: '操作人',
  logs_th5: 'IMEI',
  logs_th6: '操作内容',
  logs_th7: '日志来源',
  logs_modalTitle: '日志内容',
  logs_startTime: '开始时间',
  logs_endTime: '结束时间',
  logs_userNameEmpty: '操作用户不能为空',
  logs_contentEmpty: '操作内容不能为空',
  logs_userNamePlaceholder: '请输入操作用户',
  logs_contentPlaceholder: '请输入操作内容',
  logs_search: '查询',
  logs_cancel: '取消',
  logs_empty: '清空',
  logs_noData: '暂无数据',
  logs_total: '共',
  logs_unit: '条',
  logs_source1: '终端上传',
  logs_source2: '平台下发',
  logs_source3: '平台操作',
  logs_source4: 'APP操作',
};

export default logs_CN;